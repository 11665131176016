export default (function () {
  document.addEventListener("DOMContentLoaded", function () {
    // メールアドレス
    const emailInput = document.getElementById("email");
    const emailLabel = document.getElementById("email-label");

    // パスワード
    const passwordInput = document.getElementById("password");
    const passwordLabel = document.getElementById("password-label");

    // ログインボタン
    const loginBtn = document.getElementById("login-btn");

    const emailInputChange = (event) => {
      const val = event.target.value;

      if (!val) {
        emailInput.classList.add("required");
        emailInput.placeholder = "メールアドレス";

        emailLabel.innerText = "必須入力です";
        emailLabel.classList.add("required-text");

        loginBtn.setAttribute("disabled", "");
        return;
      }

      emailInput.classList.remove("required");
      emailInput.placeholder = "";

      emailLabel.innerText = "メールアドレス";
      emailLabel.classList.remove("required-text");

      if (passwordInput.value) {
        loginBtn.removeAttribute("disabled");
      }
    };

    const passwordInputChange = (event) => {
      const val = event.target.value;

      if (!val) {
        passwordInput.classList.add("required");
        passwordInput.placeholder = "パスワード";

        passwordLabel.innerText = "必須入力です";
        passwordLabel.classList.add("required-text");

        loginBtn.setAttribute("disabled", "");

        return;
      }

      passwordInput.classList.remove("required");
      passwordInput.placeholder = "";

      passwordLabel.innerText = "パスワード";
      passwordLabel.classList.remove("required-text");

      if (emailInput.value) {
        loginBtn.removeAttribute("disabled");
      }
    };

    emailInput.addEventListener("input", emailInputChange);
    passwordInput.addEventListener("input", passwordInputChange);
  });
})();
